.ck-content {

  .ck-horizontal-line {
    @apply mb-4;
  }

  hr {
    width: 100px;
    border-top: 1px solid #aaa;
    height: 1px;
    margin: 1em auto;
  }

  h3 {
    &.category {
      font-family: 'Bebas Neue', serif;
      //font-size: 20px;
      font-weight: bold;
      //color: #d1d1d1;
      letter-spacing: 10px;
      @apply m-0 p-0 opacity-75;
    }

    &.document-subtitle {
      @apply text-lg text-pink-700 m-0 mb-4 font-normal p-0;
    }
  }

  h2 {
    &.document-title {
      font-family: 'Bebas Neue', serif;
      font-size: 2em;
      font-weight: bold;
     //line-height: 3rem;
      @apply m-0 p-0 border-0;
    }
  }

  a {
    @apply underline underline-offset-1;
  }

  p {
    &.info-box {
      --background-size: 30px;
      --background-color: rgb(var(--color-primary));
      @apply p-6 border mb-4 mt-4 sm:m-8 shadow-md rounded-lg;

      border-color: var(--background-color);
      background: linear-gradient(135deg, var(--background-color) 0%, var(--background-color) var(--background-size), transparent var(--background-size)), linear-gradient(135deg, transparent calc(100% - var(--background-size)), var(--background-color) calc(100% - var(--background-size)), var(--background-color));
    }
  }

  ol, ul {
    margin-left: 48px;
  }

  blockquote {
    &.side-quote {
      font-family: 'Bebas Neue', serif;
      font-style: normal;
      @apply float-right md:w-1/3 relative border-0 ml-4 overflow-visible;

      &::before {
        content: "“";
        position: absolute;
        top: -52px;
        left: 0;
        display: block;
        font-size: 200px;
        color: #e7e7e7;
        z-index: -1;
        line-height: 1;
      }

      p {
        font-family: 'Bebas Neue', serif;
        font-size: 2em;
        @apply leading-none;

        &:last-child:not(:first-child) {
          font-size: 1.3em;
          @apply text-right text-gray-400;
        }
      }
    }
  }

  span {
    &.marker {
      @apply bg-yellow-500;
    }

    &.spoiler {
      @apply bg-black text-black dark:bg-white;

      &:hover {
        @apply bg-black text-white dark:text-black;
      }
    }
  }

  pre {
    &.fancy-code {
      @apply m-0 mb-4 mt-4 sm:ml-8 sm:mr-8 overflow-scroll border-0 p-4 rounded;

      &::before {
        content: "";
        display: block;
        height: 13px;
        background: url(data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NCAxMyI+CiAgPGNpcmNsZSBjeD0iNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGMzZCNUMiLz4KICA8Y2lyY2xlIGN4PSIyNi41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiNGOUJFNEQiLz4KICA8Y2lyY2xlIGN4PSI0Ny41IiBjeT0iNi41IiByPSI2LjUiIGZpbGw9IiM1NkM0NTMiLz4KPC9zdmc+Cg==);
        margin-bottom: 8px;
        background-repeat: no-repeat;
      }

      &-dark {
        background: #272822;
        color: #fff;
        box-shadow: 5px 5px 0 #0000001f;
      }

      &-bright {
        background: #dddfe0;
        color: #000;
        box-shadow: 5px 5px 0 #b3b3b3;
      }
    }
  }
}
