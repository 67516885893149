// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// SCSS Variables
@use 'var';

// Mixins
@import 'partials/mixins';

// Fonts
@import 'partials/fonts/inter';
@import 'partials/fonts/monospaced';
@import 'partials/fonts/material-icons';

// Styles / CSS Variables
@import 'partials/styles/style-dark';
@import 'partials/styles/style-light';
@import 'partials/styles/style-default';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
// @include mat.legacy-typography-hierarchy($config);
//@include mat.all-component-typographies($config);
@include mat.core();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($hop-theme);

// Partials
@import 'partials/horizontal';
@import 'partials/misc';
@import 'partials/vertical';
@import 'partials/print';
@import 'partials/typography';
@import 'partials/plugins/@angular/material/index';
@import 'partials/plugins/apexcharts/apexcharts';

// Layouts
//@import "partials/layouts/layout-apollo";
//@import "partials/layouts/layout-ares";
//@import "partials/layouts/layout-hermes";
@import 'partials/layouts/layout-hop';
//@import "partials/layouts/layout-poseidon";
//@import "partials/layouts/layout-zeus";

// Plus imports for other components in your app.

/* You can add global styles to this file, and also import other style files */

html {
  box-sizing: border-box;
  font-size: 17px;
  height: 100%;
  font-feature-settings: 'cv02', 'cv03', 'cv04', 'cv11', 'salt', 'calt', 'case';
  @apply font-sans leading-normal antialiased;
}

body {
  height: 100%;
  letter-spacing: -0.006em;
  @apply text-sm text-black;
}

div {
  box-sizing: border-box;
}

.p-page-container {
  @apply p-2 xs:p-4 sm:p-8;
  @apply py-2 xs:py-4 sm:py-8;
}

#section1 {
  @apply p-page-container;
}