:root {
  /* Overrides the border radius setting in the theme. */
  --ck-border-radius: 4px;

  /* Overrides the default font size in the theme. */
  --ck-font-size-base: 14px;

  /* Helper variables to avoid duplication in the colors. */
  --ck-custom-background: hsl(270, 1%, 29%);
  --ck-custom-foreground: hsl(255, 3%, 18%);
  --ck-custom-border: hsl(300, 1%, 22%);
  --ck-custom-white: hsl(0, 27%, 38%);

  /* -- Overrides generic colors. ------------------------------------------------------------- */

  --ck-color-base-foreground: var(--ck-custom-background);
  --ck-color-focus-border: hsl(208, 90%, 62%);
  --ck-color-text: hsl(0, 0%, 98%);
  --ck-color-shadow-drop: hsla(0, 0%, 0%, 0.2);
  --ck-color-shadow-inner: hsla(0, 0%, 0%, 0.1);

  /* -- Overrides the default .ck-button class colors. ---------------------------------------- */

  --ck-color-button-default-background: var(--ck-custom-background);
  --ck-color-button-default-hover-background: hsl(270, 1%, 22%);
  --ck-color-button-default-active-background: hsl(270, 2%, 20%);
  --ck-color-button-default-active-shadow: hsl(270, 2%, 23%);
  --ck-color-button-default-disabled-background: var(--ck-custom-background);

  --ck-color-button-on-background: var(--ck-custom-foreground);
  --ck-color-button-on-hover-background: hsl(255, 4%, 16%);
  --ck-color-button-on-active-background: hsl(255, 4%, 14%);
  --ck-color-button-on-active-shadow: hsl(240, 3%, 19%);
  --ck-color-button-on-disabled-background: var(--ck-custom-foreground);

  --ck-color-button-action-background: hsl(168, 76%, 42%);
  --ck-color-button-action-hover-background: hsl(168, 76%, 38%);
  --ck-color-button-action-active-background: hsl(168, 76%, 36%);
  --ck-color-button-action-active-shadow: hsl(168, 75%, 34%);
  --ck-color-button-action-disabled-background: hsl(168, 76%, 42%);
  --ck-color-button-action-text: var(--ck-custom-white);

  --ck-color-button-save: hsl(120, 100%, 46%);
  --ck-color-button-cancel: hsl(15, 100%, 56%);

  /* -- Overrides the default .ck-dropdown class colors. -------------------------------------- */

  --ck-color-dropdown-panel-background: var(--ck-custom-background);
  --ck-color-dropdown-panel-border: var(--ck-custom-foreground);

  /* -- Overrides the default .ck-splitbutton class colors. ----------------------------------- */

  --ck-color-split-button-hover-background: var(--ck-color-button-default-hover-background);
  --ck-color-split-button-hover-border: var(--ck-custom-foreground);

  /* -- Overrides the default .ck-input class colors. ----------------------------------------- */

  --ck-color-input-background: var(--ck-custom-background);
  --ck-color-input-border: hsl(257, 3%, 43%);
  --ck-color-input-text: hsl(0, 0%, 98%);
  --ck-color-input-disabled-background: hsl(255, 4%, 21%);
  --ck-color-input-disabled-border: hsl(250, 3%, 38%);
  --ck-color-input-disabled-text: hsl(0, 0%, 78%);

  /* -- Overrides the default .ck-labeled-field-view class colors. ---------------------------- */

  --ck-color-labeled-field-label-background: var(--ck-custom-background);

  /* -- Overrides the default .ck-list class colors. ------------------------------------------ */

  --ck-color-list-background: var(--ck-custom-background);
  --ck-color-list-button-hover-background: var(--ck-color-base-foreground);
  --ck-color-list-button-on-background: var(--ck-color-base-active);
  --ck-color-list-button-on-background-focus: var(--ck-color-base-active-focus);
  --ck-color-list-button-on-text: var(--ck-color-base-background);

  /* -- Overrides the default .ck-balloon-panel class colors. --------------------------------- */

  --ck-color-panel-background: var(--ck-custom-background);
  --ck-color-panel-border: var(--ck-custom-border);

  /* -- Overrides the default .ck-toolbar class colors. --------------------------------------- */

  --ck-color-toolbar-background: var(--ck-custom-background);
  --ck-color-toolbar-border: var(--ck-custom-border);

  /* -- Overrides the default .ck-tooltip class colors. --------------------------------------- */

  --ck-color-tooltip-background: hsl(252, 7%, 14%);
  --ck-color-tooltip-text: hsl(0, 0%, 93%);

  /* -- Overrides the default colors used by the ckeditor5-image package. --------------------- */

  --ck-color-image-caption-background: hsl(0, 0%, 97%);
  --ck-color-image-caption-text: hsl(0, 0%, 20%);

  /* -- Overrides the default colors used by the ckeditor5-widget package. -------------------- */

  --ck-color-widget-blurred-border: hsl(0, 0%, 87%);
  --ck-color-widget-hover-border: hsl(43, 100%, 68%);
  --ck-color-widget-editable-focus-background: var(--ck-custom-white);

  /* -- Overrides the default colors used by the ckeditor5-link package. ---------------------- */

  --ck-color-link-default: hsl(190, 100%, 75%);
}

.ck-content {
  @apply text-base;
  p {
    @apply text-base mb-2;
  }
  code {
    background-color: hsla(0, 0%, 78%, 0.3);
    border-radius: 2px;
    padding: 0.15em;
  }

  blockquote {
    border-left: 5px solid #ccc;
    font-style: italic;
    margin-left: 0;
    margin-right: 0;
    overflow: hidden;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  &[dir=rtl] blockquote {
    border-left: 0;
    border-right: 5px solid #ccc;
  }

  pre {
    background: hsla(0, 0%, 78%, 0.3);
    border: 1px solid #c4c4c4;
    border-radius: 2px;
    direction: ltr;
    font-style: normal;
    min-width: 200px;
    padding: 1em;
    tab-size: 4;
    text-align: left;
    white-space: pre-wrap;

    code {
      background: unset;
      border-radius: 0;
      padding: 0;
    }
  }

  .text-tiny {
    font-size: 0.7em;
  }

  .text-small {
    font-size: 0.85em;
  }

  .text-big {
    font-size: 1.4em;
  }

  .text-huge {
    font-size: 1.8em;
  }
}

:root {
  --ck-highlight-marker-yellow:#fdfd77;
  --ck-highlight-marker-green:#62f962;
  --ck-highlight-marker-pink:#fc7899;
  --ck-highlight-marker-blue:#72ccfd;
  --ck-highlight-pen-red:#e71313;
  --ck-highlight-pen-green:#128a00;
}

:root {
  --ck-link-image-indicator-icon-size: 20;
  --ck-link-image-indicator-icon-is-visible: clamp(0px,100% - 50px,1px);
}

.ck-content {
  a {
    //color: var.$color-primary;
    @apply underline underline-offset-1;
  }
  .marker-yellow {
    background-color: var(--ck-highlight-marker-yellow);
  }

  .marker-green {
    background-color: var(--ck-highlight-marker-green);
  }

  .marker-pink {
    background-color: var(--ck-highlight-marker-pink);
  }

  .marker-blue {
    background-color: var(--ck-highlight-marker-blue);
  }

  .pen-red {
    background-color: transparent;
    color: var(--ck-highlight-pen-red);
  }

  .pen-green {
    background-color: transparent;
    color: var(--ck-highlight-pen-green);
  }

  hr {
    background: #dedede;
    border: 0;
    height: 4px;
    margin: 15px 0;
  }

  .image {
    clear: both;
    display: table;
    margin: .9em auto;
    min-width: 50px;
    text-align: center;

    img {
      display: block;
      height: auto;
      margin: 0 auto;
      max-width: 100%;
      min-width: 100%;
    }
    &>a {
      position: relative;
      &:after {
        display: block;
        position: absolute;
        background-color: rgba(0,0,0,.4);
        background-image: url(data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjAgMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbD0iI2ZmZiIgZD0ibTExLjA3NyAxNSAuOTkxLTEuNDE2YS43NS43NSAwIDEgMSAxLjIyOS44NmwtMS4xNDggMS42NGEuNzQ4Ljc0OCAwIDAgMS0uMjE3LjIwNiA1LjI1MSA1LjI1MSAwIDAgMS04LjUwMy01Ljk1NS43NDEuNzQxIDAgMCAxIC4xMi0uMjc0bDEuMTQ3LTEuNjM5YS43NS43NSAwIDEgMSAxLjIyOC44Nkw0LjkzMyAxMC43bC4wMDYuMDAzYTMuNzUgMy43NSAwIDAgMCA2LjEzMiA0LjI5NGwuMDA2LjAwNHptNS40OTQtNS4zMzVhLjc0OC43NDggMCAwIDEtLjEyLjI3NGwtMS4xNDcgMS42MzlhLjc1Ljc1IDAgMSAxLTEuMjI4LS44NmwuODYtMS4yM2EzLjc1IDMuNzUgMCAwIDAtNi4xNDQtNC4zMDFsLS44NiAxLjIyOWEuNzUuNzUgMCAwIDEtMS4yMjktLjg2bDEuMTQ4LTEuNjRhLjc0OC43NDggMCAwIDEgLjIxNy0uMjA2IDUuMjUxIDUuMjUxIDAgMCAxIDguNTAzIDUuOTU1em0tNC41NjMtMi41MzJhLjc1Ljc1IDAgMCAxIC4xODQgMS4wNDVsLTMuMTU1IDQuNTA1YS43NS43NSAwIDEgMS0xLjIyOS0uODZsMy4xNTUtNC41MDZhLjc1Ljc1IDAgMCAxIDEuMDQ1LS4xODR6Ii8+PC9zdmc+);
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 14px;
        border-radius: 100%;
        content: "";
        height: calc(var(--ck-link-image-indicator-icon-is-visible)*var(--ck-link-image-indicator-icon-size));
        overflow: hidden;
        right: min(var(--ck-spacing-medium),6%);
        top: min(var(--ck-spacing-medium),6%);
        width: calc(var(--ck-link-image-indicator-icon-is-visible)*var(--ck-link-image-indicator-icon-size));
      }
    }

  }

  .image-inline {
    align-items: flex-start;
    display: inline-flex;
    max-width: 100%;

    picture {
      display: flex;
    }

    img, picture {
      flex-grow: 1;
      flex-shrink: 1;
      max-width: 100%;
    }
  }
}

:root {
  --ck-color-image-caption-background:#f7f7f7;
  --ck-color-image-caption-text:#333;
  --ck-color-image-caption-highligted-background:#fd0;
  --ck-border-radius: 2px;
  --ck-inner-shadow: 2px 2px 3px var(--ck-color-shadow-inner) inset;
  --ck-drop-shadow: 0 1px 2px 1px var(--ck-color-shadow-drop);
  --ck-drop-shadow-active: 0 3px 6px 1px var(--ck-color-shadow-drop-active);
  --ck-spacing-unit: 0.6em;
  --ck-spacing-large: calc(var(--ck-spacing-unit)*1.5);
  --ck-spacing-standard: var(--ck-spacing-unit);
  --ck-spacing-medium: calc(var(--ck-spacing-unit)*0.8);
  --ck-spacing-small: calc(var(--ck-spacing-unit)*0.5);
  --ck-spacing-tiny: calc(var(--ck-spacing-unit)*0.3);
  --ck-spacing-extra-tiny: calc(var(--ck-spacing-unit)*0.16);
}

.ck-content {
  .image > figcaption {
    background-color: var(--ck-color-image-caption-background);
    caption-side: bottom;
    color: var(--ck-color-image-caption-text);
    display: table-caption;
    font-size: .75em;
    outline-offset: -1px;
    padding: .6em;
    word-break: break-word;
  }

  img.image_resized {
    height: auto;
  }

  .image.image_resized {
    box-sizing: border-box;
    display: block;
    max-width: 100%;

    img {
      width: 100%;
    }

    > figcaption {
      display: block;
    }
  }
}

:root {
  --ck-image-style-spacing:1.5em;
  --ck-inline-image-style-spacing:calc(var(--ck-image-style-spacing) / 2);
}

.ck-content {
  .image-style-block-align-left, .image-style-block-align-right {
    max-width: calc(100% - var(--ck-image-style-spacing));
  }

  .image-style-align-left, .image-style-align-right {
    clear: none;
  }

  .image-style-side {
    float: right;
    margin-left: var(--ck-image-style-spacing);
    max-width: 50%;
  }

  .image-style-align-left {
    float: left;
    margin-right: var(--ck-image-style-spacing);
  }

  .image-style-align-center {
    margin-left: auto;
    margin-right: auto;
  }

  .image-style-align-right {
    float: right;
    margin-left: var(--ck-image-style-spacing);
  }

  .image-style-block-align-right {
    margin-left: auto;
    margin-right: 0;
  }

  .image-style-block-align-left {
    margin-left: 0;
    margin-right: auto;
  }

  p + {
    .image-style-align-left, .image-style-align-right, .image-style-side {
      margin-top: 0;
    }
  }

  .image-inline {
    &.image-style-align-left, &.image-style-align-right {
      margin-bottom: var(--ck-inline-image-style-spacing);
      margin-top: var(--ck-inline-image-style-spacing);
    }

    &.image-style-align-left {
      margin-right: var(--ck-inline-image-style-spacing);
    }

    &.image-style-align-right {
      margin-left: var(--ck-inline-image-style-spacing);
    }
  }

  ol {
    list-style-type: decimal;

    ol {
      list-style-type: lower-latin;

      ol {
        list-style-type: lower-roman;

        ol {
          list-style-type: upper-latin;

          ol {
            list-style-type: upper-roman;
          }
        }
      }
    }
  }

  ul {
    list-style-type: disc;

    ul {
      list-style-type: circle;

      ul {
        list-style-type: square;

        ul {
          list-style-type: square;
        }
      }
    }
  }
}

:root {
  --ck-todo-list-checkmark-size:16px;
}

.ck-content {
  .todo-list {
    list-style: none;

    li {
      margin-bottom: 5px;
      position: relative;

      .todo-list {
        margin-top: 5px;
      }
    }

    .todo-list__label > input {
      -webkit-appearance: none;
      border: 0;
      display: inline-block;
      height: var(--ck-todo-list-checkmark-size);
      left: -25px;
      margin-left: 0;
      margin-right: -15px;
      position: relative;
      right: 0;
      vertical-align: middle;
      width: var(--ck-todo-list-checkmark-size);
    }
  }

  &[dir=rtl] .todo-list .todo-list__label > input {
    left: 0;
    margin-left: -15px;
    margin-right: 0;
    right: -25px;
  }

  .todo-list .todo-list__label {
    > input {
      &:before {
        border: 1px solid #333;
        border-radius: 2px;
        box-sizing: border-box;
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        transition: box-shadow .25s ease-in-out;
        width: 100%;
      }

      &:after {
        border-color: transparent;
        border-style: solid;
        border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8) calc(var(--ck-todo-list-checkmark-size) / 8) 0;
        box-sizing: content-box;
        content: "";
        display: block;
        height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
        left: calc(var(--ck-todo-list-checkmark-size) / 3);
        pointer-events: none;
        position: absolute;
        top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
        transform: rotate(45deg);
        width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
      }

      &[checked] {
        &:before {
          background: #26ab33;
          border-color: #26ab33;
        }

        &:after {
          border-color: #fff;
        }
      }
    }

    .todo-list__label__description {
      vertical-align: middle;
    }

    &.todo-list__label_without-description input[type=checkbox] {
      position: absolute;
    }
  }

  .media {
    clear: both;
    display: block;
    margin: .9em 0;
    min-width: 15em;
  }

  .table {
    display: table;
    margin: 0.9em auto;

    table {
      border: 1px double #b3b3b3;
      border-collapse: collapse;
      border-spacing: 0;
      height: 100%;
      width: 100%;

      td {
        border: 1px solid #bfbfbf;
        min-width: 2em;
        padding: 0.4em;
      }

      th {
        border: 1px solid #bfbfbf;
        min-width: 2em;
        padding: 0.4em;
        background: rgba(0, 0, 0, 0.05);
        font-weight: 700;
      }
    }
  }

  &[dir=rtl] .table th {
    text-align: right;
  }

  &[dir=ltr] .table th {
    text-align: left;
  }
}

:root {
  --ck-color-selector-caption-background:#f7f7f7;
  --ck-color-selector-caption-text:#333;
  --ck-color-selector-caption-highlighted-background:#fd0;
}

.ck-content .table > figcaption {
  background-color: var(--ck-color-selector-caption-background);
  caption-side: top;
  color: var(--ck-color-selector-caption-text);
  display: table-caption;
  font-size: .75em;
  outline-offset: -1px;
  padding: .6em;
  text-align: center;
  word-break: break-word;
}

:root {
  --ck-color-selector-column-resizer-hover:var(--ck-color-base-active);
  --ck-table-column-resizer-width:7px;
  --ck-table-column-resizer-position-offset:calc(var(--ck-table-column-resizer-width) * -0.5 - 0.5px);
}

.ck-content .table {
  .ck-table-resized {
    table-layout: fixed;
  }

  table {
    overflow: hidden;
  }

  td, th {
    overflow-wrap: break-word;
    position: relative;
  }
}
