@use '@angular/material' as mat;

// Previously "$sidenav-background"
$sidenav-background: #1a202e !default;
$color-primary: rgb(62, 133, 234);
$color-primary-contrast: rgb(255, 255, 255);
$color-accent: rgb(255, 64, 129);
$color-accent-contrast: rgb(255, 255, 255);

$color-warn: rgb(244, 67, 54);
$color-warn-contrast: rgb(255, 255, 255);

$config: mat.m2-define-typography-config(
  $font-family: var(--font),
  $headline-1: mat.m2-define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $headline-2: mat.m2-define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $headline-3: mat.m2-define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $headline-4: mat.m2-define-typography-level(34px, 40px, 400),
  $headline-5: mat.m2-define-typography-level(24px, 32px, 400, $letter-spacing: -0.019em),
  $headline-6: mat.m2-define-typography-level(18px, 26px, 500, $letter-spacing: -0.014em),
  $subtitle-1: mat.m2-define-typography-level(16px, 28px, 400, $letter-spacing: -0.011em),
  $body-1: mat.m2-define-typography-level(16px, 24px, 400, $letter-spacing: -0.009em),
  $subtitle-2: mat.m2-define-typography-level(14px, 24px, 500, $letter-spacing: -0.006em),
  $body-2: mat.m2-define-typography-level(14px, 20px, 400, $letter-spacing: -0.006em),
  $caption: mat.m2-define-typography-level(12px, 20px, 400, $letter-spacing: 0),
  $button: mat.m2-define-typography-level(14px, 14px, 500, $letter-spacing: -0.006em),
) !default;

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

/*$hop-primary: mat.define-palette((
  100: rgba(var(--color-primary), 0.1),
  500: rgba(var(--color-primary), 1),
  700: rgba(var(--color-primary), 1),
  contrast: (
    100: rgba(var(--color-primary-congtrast), 1),
    500: rgba(var(--color-primary-congtrast), 1),
    700: rgba(var(--color-primary-congtrast), 1),
  )
), 500) !default;*/










$hop-theme-foreground: (
  text: black,
  elevation: #000,
  divider: rgba(82, 63, 105, 0.06)
) !default;

$hop-theme-background: (
  app-bar: #ebebee
) !default;




/*$my-primary: mat.define-palette(mat.$blue-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The "warn" palette is optional and defaults to red if not specified.
$my-warn: mat.define-palette(mat.$red-palette);*/





$light-primary-text: white;
$light-secondary-text: rgba(white, 0.7);
$light-disabled-text: rgba(white, 0.5);

$dark-primary-text: rgba(black, 0.87);
$dark-secondary-text: rgba(black, 0.54);
$dark-disabled-text: rgba(black, 0.38);
