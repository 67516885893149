@use '@angular/material' as mat;
@use '../var';
//@use 'node_modules/utopia-core-scss/src/utopia' as utopia;
// Typography

/*
  InterDynamicTracking takes the font size in points
  or pixels and returns the compensating tracking in EM.
  function InterDynamicTracking(fontSize) {
    const a = -0.0223
    const b = 0.185
    const c = -0.1745;
    // tracking = a + b * e ^ (c * fontSize)
    return a + b * Math.pow(Math.E, c * fontSize)
  }
*/

/* @link https://utopia.fyi/type/calculator?c=320,14,1.067,1440,16,1.125,5,0,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */

/* @link https://utopia.fyi/type/calculator?c=320,14,1.067,1280,16,1.125,5,0,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */

/* @link https://utopia.fyi/type/calculator?c=320,14,1.067,1280,16,1.125,5,0,&s=0.75|0.5|0.25,1.5|2|3|4|6,s-l&g=s,l,xl,12 */

:root {
  --step-0: clamp(0.875rem, 0.8333rem + 0.2083vi, 1rem);
  --step-1: clamp(0.9336rem, 0.8698rem + 0.319vi, 1.125rem);
  --step-2: clamp(0.9962rem, 0.9064rem + 0.4491vi, 1.2656rem);
  --step-3: clamp(1.0629rem, 0.9426rem + 0.6015vi, 1.4238rem);
  --step-4: clamp(1.1341rem, 0.9782rem + 0.7794vi, 1.6018rem);
  --step-5: clamp(1.2101rem, 1.0128rem + 0.9865vi, 1.802rem);
}

body {
  font-size: 100%;
}

/*p {
  font-size: var(--step-1);
  line-height: 1.5;
}*/
.title {
  font-size: var(--step-1);
  font-weight: bold;
  line-height: 1.5;
}

.display-4,
.body-4 {
  @include mat.m2-typography-level(var.$config, body-2);
}

.display-3,
.body-3 {
  @include mat.m2-typography-level(var.$config, body-2);
}

.display-2 {
  @include mat.m2-typography-level(var.$config, body-2);
}

.display-1,
h1 {
  font-size: var(--step-5);
  line-height: 1.4;
  @apply text-left leading-normal font-bold; //text-3xl  md:text-4xl;
  /*@include mat.typography-level(var.$config, body-1);*/
}

h2,
.headline-4 {
  font-size: var(--step-4);
  line-height: 1.4;
  @apply text-left leading-normal font-bold; //text-3xl  md:text-4xl;
  /*@include mat.typography-level(var.$config, body-1);*/
}

h3,
.headline-6 {
  //@include mat.typography-level(var.$config, headline-6);
  @apply font-sans font-bold;
  font-size: var(--step-3);
  line-height: 1.5;
}

.subheading-2,
h4,
.subtitle-2 {
  @apply font-sans font-semibold;
  font-size: var(--step-2);
  line-height: 1.2;
  //@include mat.typography-level(var.$config, subtitle-2);
}

.subheading-1,
h5,
subtitle-1 {
  @include mat.m2-typography-level(var.$config, subtitle-1);
}

.body-2,
h6 {
  @include mat.m2-typography-level(var.$config, body-2);
}

.headline,
.body-1,
p {
  //@include mat.typography-level(var.$config, body-1);
  @apply text-base;
  line-height: 1.6;
}

.caption {
  @include mat.m2-typography-level(var.$config, caption);
}

.text-xs {
  letter-spacing: 0;
}

.text-sm {
  letter-spacing: -0.009em;
}

.text-base {
  font-size: var(--step-1);
  //line-height: 1.2;
  letter-spacing: -0.011em;
  @apply leading-relaxed;
}

.text-lg {
  letter-spacing: -0.014em;
}

.text-2xl {
  letter-spacing: -0.019em;
}

.text-3xl {
  letter-spacing: -0.021em;
}