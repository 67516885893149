@tailwind base;
input, textarea {
  box-sizing: content-box;
  line-height: 1.5;
}

button:focus {
  outline: unset !important;
}

/*code {
  background: var(--background-app-bar);
  border-radius: var(--border-radius);
  color: var(--text-color);
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 85%;
  padding: .2em .4em;
}*/
.dark blockquote {
  color: rgba(224, 224, 224, 0.87);
}

blockquote {
  background: rgba(var(--color-primary), 0.1);
  border-left: 3px solid theme("backgroundColor.primary");
  color: rgba(0, 0, 0, 0.87);
  font-style: normal;
  margin: 1em 0 1.5em;
  padding: 1em 1.5em !important;
}
blockquote > * {
  margin: 0;
}

@tailwind components;
@tailwind utilities;
/*$hop-primary: mat.define-palette((
  100: rgba(var(--color-primary), 0.1),
  500: rgba(var(--color-primary), 1),
  700: rgba(var(--color-primary), 1),
  contrast: (
    100: rgba(var(--color-primary-congtrast), 1),
    500: rgba(var(--color-primary-congtrast), 1),
    700: rgba(var(--color-primary-congtrast), 1),
  )
), 500) !default;*/
/*$my-primary: mat.define-palette(mat.$blue-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The "warn" palette is optional and defaults to red if not specified.
$my-warn: mat.define-palette(mat.$red-palette);*/
.container {
  @apply mx-2 xs:mx-4 md:mx-8;
}
@screen lg {
  .container {
    margin-left: auto;
    margin-right: auto;
    max-width: theme("screens.lg");
    width: 100%;
  }
}

.card {
  @apply rounded shadow bg-foreground;
}

.cardAuth {
  @apply rounded shadow bg-foreground my-12;
}

.avatar {
  @apply h-10 w-10 object-cover rounded-full bg-hover;
}

.hidden-input {
  @apply border-2 border-solid border-transparent py-1 px-3 rounded;
}
.hidden-input:focus {
  @apply border-primary outline-none;
}
.hidden-input.empty {
  @apply border-primary;
}

.bg-pattern {
  /*background: linear-gradient(
    135deg,
    var(--background-base) 22px,
    var(--background-hover) 22px,
    var(--background-hover) 24px,
    transparent 24px,
    transparent 67px,
    var(--background-hover) 67px,
    var(--background-hover) 69px,
    transparent 69px
  ),
  linear-gradient(
      225deg,
      var(--background-base) 22px,
      var(--background-hover) 22px,
      var(--background-hover) 24px,
      transparent 24px,
      transparent 67px,
      var(--background-hover) 67px,
      var(--background-hover) 69px,
      transparent 69px
    )
    0 64px;*/
  /*background: var(--background-base) repeating-linear-gradient(
                  transparent -95%, transparent 14%,
                  var(--background-base) 14%, var(--background-base) 28%
  );*/
  background-size: 64px 128px;
  background: var(--background-base);
}

.trans-shadow {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}